<template>
  <Board />
</template>

<script>
import Board from "@/components/organisms/Board";
export default {
  components: {
    Board,
  },
};
</script>
