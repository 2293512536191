<template>
  <div :class="$style.container">
    <div>
      <h3 :class="$style.title">{{ getTest.title }}</h3>
      <p :class="$style.text">{{ getTest.text }}</p>
      <p
        v-if="getTest.text === 'Пользователь уже существует'"
        :class="$style.login"
      >
        <a href="">Войти</a>
      </p>
    </div>
    <img
      @click="$emit('click')"
      :class="$style.cross"
      src="@/assets/icons/cross.svg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    getError() {
      return this.$store.state.auth.error.text;
    },
    getTest() {
      let title = "";
      let text = "";
      if (this.type === "success") {
        title = "Поздравляем!";
        text =
          "Ты успешно зарегистрировался. Осталось проверить почту и подтвердить свой e-mail";
      } else {
        title = "Упс";
        let errors = {
          "User already exist": "Пользователь уже существует",
          "Email was not sent error":
            "Ошибка регистрации, проверьте введенный e-mail и попробуйте еще раз",
          "undefined error": "Ошибка создания пользователя",
          "wrong password": "Проверьте пароль",
          "this account does not exist": "Пользователь не существует",
        };
        text =
          errors[this.getError] || "Что-то пошло не так, попробуйте еще раз";
      }

      return { title, text };
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 17.3rem;
  height: 21rem;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 2rem;
  text-align: center;
  @include cardShadow;
  padding: 0 1.5rem;
  .title {
    @include H600;
    margin: 0 0 0.5rem 0;
  }
  .text {
    @include H500M;
  }
  .login {
    margin: 1.5rem 0 0 0;
    @include H400;
    color: $N600;
    a {
      color: $B500;
      display: inline-block;
      margin: 0 0 0 0.5rem;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: -0.05rem;
        left: 0;
        height: 1px;
        background-color: $B500;
      }
    }
  }
  .cross {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}
</style>
