<template>
  <div :class="$style.container">
    <input
      :class="[$style.input, { [$style.error]: isError }]"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus')"
    />
    <p v-if="isError" :class="$style.errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
      validator: (value) =>
        ["text", "number", "email", "password"].includes(value),
    },
    placeholder: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" module>
.input {
  outline: none;
  border: none;
  display: block;
  width: 19.5rem;
  background-color: $white;
  padding: 0.9375rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid $N3-400;
  @include H300;
  color: $N400;
  &.error {
    border: 1px solid $R500;
  }
}
.errorMessage {
  margin: 0.25rem 0 0 0;
  text-align: left;
  @include H100;
  color: $R500;
}
</style>
