<template>
  <button
    :class="[$style.button, { [$style.big]: big }, { [$style.white]: white }]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.button {
  display: inline-block;
  cursor: pointer;
  @include H400;
  color: $white;
  white-space: nowrap;
  outline: 0;
  padding: 0.5rem 1rem;
  border-radius: 0.625rem;
  background-color: $B500;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  &.big {
    padding: 0.9375rem 3rem;
  }
  &.white {
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
  }
  &.white:hover {
    color: $B500;
    background-color: $white;
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
  }
  &:hover {
    box-shadow: $primaryShadow;
  }
}
</style>
