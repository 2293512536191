<template>
  <div :class="$style.board">
    <div
      v-for="list in board.lists"
      :key="list.name"
      @drop.prevent="onDrop($event, list.name)"
      @dragover.prevent
      @dragenter.prevent
      :class="$style.list"
    >
      <h3 :class="$style.name">{{ list.name }}</h3>
      <Task
        v-for="task in board.tasks.filter((task) => task.status === list.name)"
        :key="task.id"
        @dragstart="onDragStart($event, task)"
        draggable="true"
        :name="task.name"
        :icon="task.icon"
        :grade="task.grade"
        :class="$style.task"
      />
    </div>
  </div>
</template>

<script>
import Task from "@/components/molecules/Task";
export default {
  components: {
    Task,
  },
  data() {
    return {
      board: {
        lists: [
          {
            name: "Todo",
          },
          {
            name: "In Progress",
          },
        ],
        tasks: [
          {
            id: 1,
            name: "Task 1",
            grade: 1,
            status: "Todo",
          },
          {
            id: 2,
            name: "Task 2",
            grade: 5,
            status: "In Progress",
          },
          {
            id: 3,
            name: "Task 3",
            grade: 3,
            status: "Todo",
          },
        ],
      },
    };
  },
  methods: {
    onDragStart(e, item) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowtd = "move";
      e.dataTransfer.setData("taskId", item.id);
    },
    onDrop(e, listName) {
      const taskId = e.dataTransfer.getData("taskId");
      this.board.tasks = this.board.tasks.map((task) =>
        task.id == taskId ? { ...task, status: listName } : task
      );
    },
  },
};
</script>

<style lang="scss" module>
.board {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  overflow-x: auto;
  height: 100%;
  padding: 2rem 0 0 1rem;
  .list {
    overflow-y: auto;
    border-right: 1px solid $B500;
    padding: 0 1rem;
    .name {
      margin: 0 0 2rem 0;
    }
    .task {
      margin: 0 0 1rem 0;
    }
  }
}
</style>
