import Repository from "@/repository";

export default {
  state: {
    isLoading: false,
    isSuccess: false,
    error: {
      isError: false,
      text: "",
    },
    token: localStorage.getItem("refreshToken") || null,
    isFirstLoading: true,
  },
  actions: {
    async signup({ commit }, user) {
      commit("changeIsLoading", true);

      const { value, error } = await Repository.Core.AuthActions.signup(user);

      if (error || !value)
        commit("changeError", { isError: true, text: error });
      else commit("changeIsSuccess", true);

      commit("changeIsLoading", false);

      return { value, error };
    },

    async initTokens({ state, commit, dispatch }) {
      if (state.token && state.token !== "undefined") {
        dispatch("refresh");
      } else {
        commit("setIsFirstLoading", false);
      }
    },

    async signin({ commit, dispatch }, user) {
      const { value, error } = await Repository.Core.AuthActions.signin(
        user.email,
        user.password
      );
      if (!value || error) {
        commit("changeError", { isError: true, text: error });
        return;
      }
      commit("setToken", value.accessToken);
      localStorage.setItem("accessToken", value.accessToken);
      localStorage.setItem("refreshToken", value.refreshToken);

      dispatch("refreshTokenTimeout");

      return { error };
    },

    async refresh({ commit, dispatch }) {
      const refreshToken = localStorage.getItem("refreshToken");
      const { value, error } = await Repository.Core.AuthActions.refresh(
        refreshToken
      );

      if (error) {
        console.log(error);
      }

      commit("setToken", value.accessToken);

      commit("setIsFirstLoading", false);
      localStorage.setItem("accessToken", value.accessToken);
      localStorage.setItem("refreshToken", value.refreshToken);

      dispatch("refreshTokenTimeout");
    },

    async refreshTokenTimeout({ dispatch }) {
      setTimeout(() => dispatch("refresh"), 10 * 60 * 1000);
    },

    async deleteToken({ commit }) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      commit("deleteToken");
    },
  },
  mutations: {
    changeIsLoading(state, value) {
      state.isLoading = value;
    },
    changeError(state, value) {
      state.error.isError = value.isError;
      state.error.text = value.text;
    },
    changeIsSuccess(state, value) {
      state.isSuccess = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setIsFirstLoading(state, value) {
      state.isFirstLoading = value;
    },
    deleteToken(state) {
      state.token = null;
    },
  },
  getters: {},
};
