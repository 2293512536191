import Axios from "axios";

class ApiConnector {
  connector;

  constructor() {
    this.connector = Axios.create({
      baseURL: "/api",
    });
  }
}

export default new ApiConnector();
