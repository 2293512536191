<template>
  <router-view v-if="!$store.state.auth.isFirstLoading" />
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("initTokens");
  },
};
</script>
