<template>
  <form @submit.prevent="submit">
    <h2 :class="$style.title">С возвращением!</h2>
    <Input
      :class="$style.input"
      :type="'email'"
      :placeholder="'E-mail'"
      v-model="email"
      :isError="errors.email"
      :errorMessage="'Проверьте правильность email'"
      @focus="resetErrors('email')"
    />
    <Input
      :class="$style.input"
      :type="'password'"
      :placeholder="'Пароль'"
      v-model="password"
      :isError="errors.password"
      :errorMessage="'Пароль должен содержать минимум 8 символов'"
      @focus="resetErrors('password')"
    />
    <Button :class="$style.button" :big="true">
      <span v-if="!isLoad">Войти в систему</span>
      <p v-else :class="$style.loader">
        <img src="@/assets/icons/form/loader.svg" alt="" />
      </p>
    </Button>

    <p :class="$style.login">
      Еще нет аккаунта?
      <router-link to="/sign-up">Зарегистрироваться</router-link>
    </p>
  </form>
</template>

<script>
import Input from "@/components/atoms/Input.vue";
import Button from "@/components/atoms/Button.vue";
export default {
  components: {
    Input,
    Button,
  },
  computed: {
    isLoad() {
      return this.$store.state.auth.isLoading;
    },
  },
  data() {
    return {
      email: "",
      password: "",
      isError: false,
      errors: {
        email: false,
        password: false,
      },
    };
  },
  methods: {
    async submit() {
      this.validateForm();
      if (!this.isError) {
        try {
          let user = {
            email: this.email,
            password: this.password,
          };
          const { error } = await this.$store.dispatch("signin", user);
          if (!error) {
            this.resetForm();
            this.$router.push("/main");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    validateForm() {
      if (!this.email) {
        this.isError = true;
        this.errors.email = true;
      }
      if (this.password.length < 8) {
        this.isError = true;
        this.errors.password = true;
      }
    },
    resetErrors(name) {
      this.errors[name] = false;
    },
    resetForm() {
      this.email = "";
      this.password = "";
    },
  },
};
</script>

<style lang="scss" module>
.title {
  @include H700;
  margin: 0 0 2rem 0;
}
.input {
  margin: 0 0 1rem 0;
}
.button {
  width: 100%;
  margin: 1.5rem 0 2rem 0;
  .loader {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
    animation: loader 2s infinite linear;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.login {
  @include H400;
  color: $N600;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: $B500;
    display: inline-block;
    margin: 0.5rem 0 0 0;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: -0.05rem;
      left: 0;
      height: 1px;
      background-color: $B500;
    }
  }
}
@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}
</style>
