<template>
  <form @submit.prevent="submit">
    <h2 :class="$style.title">
      Добро пожаловать<br />
      в Upgrade!
    </h2>
    <Input
      :class="$style.input"
      :type="'text'"
      :placeholder="'Имя'"
      v-model="name"
      :isError="errors.name"
      :errorMessage="'Поле должно быть заполнено'"
      @focus="resetErrors('name')"
    />
    <Input
      :class="$style.input"
      :type="'text'"
      :placeholder="'Фамилия'"
      v-model="lastName"
      :isError="errors.lastName"
      :errorMessage="'Поле должно быть заполнено'"
      @focus="resetErrors('lastName')"
    />
    <Input
      :class="$style.input"
      :type="'email'"
      :placeholder="'E-mail'"
      v-model="email"
      :isError="errors.email"
      :errorMessage="'Проверьте правильность email'"
      @focus="resetErrors('email')"
    />
    <Input
      :class="$style.input"
      :type="'password'"
      :placeholder="'Пароль'"
      v-model="password"
      :isError="errors.password"
      :errorMessage="'Пароль должен содержать минимум 8 символов'"
      @focus="resetErrors('password')"
    />
    <Input
      :class="$style.input"
      :type="'password'"
      :placeholder="'Повтори пароль'"
      v-model="confirmPassword"
      :isError="errors.confirmPassword"
      :errorMessage="'Пароли не соответствуют'"
      @focus="resetErrors('confirmPassword')"
    />
    <label
      :class="[
        $style.agreement,
        { [$style.errorMessage]: errors.confirmAgreement },
      ]"
    >
      <img
        src="@/assets/icons/form/check.svg"
        alt=""
        v-if="confirmAgreement"
        :class="$style.check"
      />
      <Field
        v-else
        :class="[
          $style.checkIcon,
          $style.field,
          { [$style.confirmError]: errors.confirmAgreement },
        ]"
      />
      <input
        v-model="confirmAgreement"
        type="checkbox"
        :class="$style.checkbox"
        @focus="resetErrors('confirmAgreement')"
      />
      <p :class="{ [$style.confirmError]: errors.confirmAgreement }">
        Я даю
        <a href="https://webdad.by/docs/agreement" target="_blank">согласие</a>
        на обработку
        <a href="https://webdad.by/docs/privacy" target="_blank"
          >персональных данных</a
        >
      </p>
    </label>
    <p v-if="errors.confirmAgreement" :class="$style.errorMessage">
      Поле должно быть заполнено
    </p>
    <Button :class="$style.button" :big="true">
      <span v-if="!isLoad">Зарегистрироваться</span>
      <p v-else :class="$style.loader">
        <img src="@/assets/icons/form/loader.svg" alt="" />
      </p>
    </Button>

    <p :class="$style.login">
      Уже есть аккаунт? <router-link to="/sign-in">Войти</router-link>
    </p>
  </form>
</template>

<script>
import Input from "@/components/atoms/Input.vue";
import Button from "@/components/atoms/Button.vue";
import Field from "@/components/atoms/Field.vue";
export default {
  components: {
    Input,
    Button,
    Field,
  },
  computed: {
    isLoad() {
      return this.$store.state.auth.isLoading;
    },
  },
  data() {
    return {
      name: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirmAgreement: false,
      isError: false,
      errors: {
        name: false,
        lastName: false,
        email: false,
        password: false,
        confirmPassword: false,
        confirmAgreement: false,
      },
    };
  },
  methods: {
    async submit() {
      this.validateForm();
      if (!this.isError) {
        try {
          let user = {
            name: this.name,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
          };
          const { error } = await this.$store.dispatch("signup", user);
          if (!error) {
            this.resetForm();
            this.$router.push("/sign-in");
          }
        } catch (error) {
          console.log("send error");
        }
      }
    },
    validateForm() {
      if (!this.name) {
        this.isError = true;
        this.errors.name = true;
      }
      if (!this.lastName) {
        this.isError = true;
        this.errors.lastName = true;
      }
      if (!this.email) {
        this.isError = true;
        this.errors.email = true;
      }
      if (this.password.length < 8) {
        this.isError = true;
        this.errors.password = true;
      }
      if (this.confirmPassword.length < 8) {
        this.isError = true;
        this.errors.confirmPassword = true;
      }
      if (!this.confirmAgreement) {
        this.isError = true;
        this.errors.confirmAgreement = true;
      }
    },
    resetErrors(name) {
      this.errors[name] = false;
    },
    resetForm() {
      this.name = "";
      this.lastName = "";
      this.email = "";
      this.password = "";
      this.confirmPassword = "";
      this.confirmAgreement = false;
    },
  },
};
</script>

<style lang="scss" module>
.title {
  @include H700;
  margin: 0 0 2rem 0;
}
.input {
  margin: 0 0 1rem 0;
}
.agreement {
  display: flex;
  @include H100;
  text-align: left;
  cursor: pointer;
  margin: 0 0 1.5rem 0;
  &.errorMessage {
    margin: 0 0 0.25rem 0;
  }
  .checkbox {
    appearance: none;
  }
  .checkIcon {
    min-width: 1rem;
    height: 1rem;
  }
  .check {
    min-width: 1rem;
    height: 1rem;
  }
  .field {
    stroke: $fieldStroke;
    &.confirmError {
      stroke: $R500;
    }
  }
  p {
    margin: 0 0 0 0.5rem;
    color: $black;
    a {
      color: $B300;
    }
  }
}
.errorMessage {
  @include H100;
  color: $R500;
  text-align: left;
  margin: 0 0 1rem 0;
}
.button {
  width: 100%;
  margin: 0 0 2rem 0;
  .loader {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
    animation: loader 2s infinite linear;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.login {
  @include H400;
  color: $N600;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: $B500;
    display: inline-block;
    margin: 0.5rem 0 0 0;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: -0.05rem;
      left: 0;
      height: 1px;
      background-color: $B500;
    }
  }
}
@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}
</style>
