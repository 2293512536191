<template>
  <section :class="$style.container">
    <div :class="$style.image">
      <img
        src="@/assets/img/sign-up/sign-up.webp"
        alt=" "
        width="913"
        height="931"
      />
    </div>
    <div :class="$style.form">
      <SignUpForm />
    </div>
    <Message
      v-if="$store.state.auth.error.isError"
      @click="$store.commit('changeError', { isError: false })"
      :type="'error'"
    />
    <Message
      v-if="$store.state.auth.isSuccess"
      @click="$store.commit('changeIsSuccess', false)"
      :type="'success'"
    />
  </section>
</template>

<script>
import SignUpForm from "@/components/organisms/SignUpForm";
import Message from "@/components/organisms/Message";
export default {
  components: {
    SignUpForm,
    Message,
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  height: 100%;
  position: relative;
  .image {
    flex: 0 0 63%;
    width: 100%;
    height: 100%;
    line-height: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .form {
    flex: 0 0 37%;
    text-align: center;
    background-color: $N300;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
}
</style>
