import { createRouter, createWebHistory } from "vue-router";
import SignUp from "@/components/pages/SignUp";
import SignIn from "@/components/pages/SignIn";
import Content from "@/components/pages/Content";
import store from "../store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.auth.token || store.state.auth.token === "undefined") {
    next();
    return;
  }
  next("/main");
};

const ifAuthenticated = (to, from, next) => {
  if (store.state.auth.token && store.state.auth.token !== "undefined") {
    next();
    return;
  }
  next("/sign-in");
};

const routes = [
  {
    path: "/sign-up",
    component: SignUp,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/sign-in",
    component: SignIn,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/main",
    component: Content,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/",
    beforeEnter: ifAuthenticated,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
