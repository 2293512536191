<template>
  <div :class="$style.task">
    <div :class="$style.top">
      <h3 :class="$style.title">{{ name }}</h3>
      <div :class="$style.icon">
        <img v-if="icon" :src="icon" alt="" />
        <img
          v-else
          src="https://ru.seaicons.com/wp-content/uploads/2015/06/Users-Add-User-icon.png"
          alt=""
          :class="$style.noImage"
        />
      </div>
    </div>
    <div v-if="grade" :class="$style.grade">
      <img
        src="https://e7.pngegg.com/pngimages/94/849/png-clipart-computer-icons-cup-encapsulated-postscript-others-miscellaneous-rectangle.png"
        alt=""
      />
      {{ grade }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    grade: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.task {
  @include cardShadow;
  padding: 0.5rem;
  cursor: pointer;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      margin: 0 0 0.75rem 0;
      @include H400;
    }
    .icon {
      flex: 0 0 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin: 0 0 0 1rem;
      img {
        width: 100%;
        height: 100%;
      }
      .noImage {
        display: none;
        cursor: pointer;
      }
    }
  }
  .grade {
    color: $B500;
    display: flex;
    align-items: center;
    img {
      width: 1rem;
      height: 1rem;
      margin: 0 0.25rem 0 0;
    }
  }
  &:hover {
    .top {
      .noImage {
        display: block;
      }
    }
  }
}
</style>
