import ApiConnector from "../connector";

class AuthActions {
  signup = async (data) => {
    const result = {
      value: false,
      error: null,
    };

    try {
      const response = await ApiConnector.connector.post(
        "/auth/sign-up/",
        data
      );
      result.value = response.data;
    } catch (e) {
      result.error = e.response.data;
    }

    return result;
  };
  signin = async (email, password) => {
    const result = {
      value: false,
      error: null,
    };

    try {
      const response = await ApiConnector.connector.post("/auth/sign-in/", {
        login: email,
        password: password,
      });
      result.value = response.data;
    } catch (e) {
      result.error = e.response.data;
    }

    return result;
  };
  refresh = async (token) => {
    const result = {
      value: false,
      error: null,
    };

    try {
      const response = await ApiConnector.connector.post(
        "/auth/refresh-token/",
        { token: token }
      );
      result.value = response.data;
    } catch (e) {
      result.error = e.response.data;
    }

    return result;
  };
}

export default new AuthActions();
